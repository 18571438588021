import { ADD_IMAGES, CLEAR_IMAGES } from "./actions";

const initialState = {
    images: [],
    loading: {
        home: false
    }
}
export function defaultReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_IMAGES:
            return {
                ...state,
                images: [...state.images, ...action.images.results]
            }
        case CLEAR_IMAGES:
            return {
                ...state,
                images: []
            }
        default:
            return state
    }
}