import React from 'react';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';
import {Divider} from '@material-ui/core';


const DrawerView = ({ history ,closeDrawer}) => (
    <div >
        <div className="appbar" style={{height:64}}>
            <div className="row-center">
            <img className="logo" src="http://farhanapps.com/projects/assets/images/fa5c8-127x128.png" alt={"logo"} />
            <span>
                Resplash 1.1
            </span>
            </div>
        </div>
        <Divider/>
        <List component="nav">
            <ListItem button onClick={() => { history.push('/'); closeDrawer()}}>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={() => { history.push('/about'); closeDrawer() }}>
                <ListItemText primary="About" />
            </ListItem>
            <ListItem button onClick={() => { history.push('/contact'); closeDrawer() }}>
                <ListItemText primary="Contact" />
            </ListItem>
        </List>
    </div>
);


DrawerView.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }),
};

export default withRouter(DrawerView);