import React, { Component } from 'react';
import '../App.css';
import {Paper} from '@material-ui/core';

class About extends Component {

    render() {
        return (
            <Paper className="page" >
                <div>
                    <h1>Resplash</h1>
                    <p>An Image gallery built in React.js using Unsplash Api </p>
                    <p>This web App supports mobile devices also</p>
                </div>
            </Paper>
        );
    }
}

export default About;
