import React, { Component } from "react";
import { Provider } from "react-redux";
import Home from "./screens/home";
import Contact from "./screens/contact";
import About from "./screens/about";
import MyTab from "./screens/Tab";
import PropTypes from "prop-types";

import { Switch, Route, BrowserRouter } from "react-router-dom";

import DrawerView from "./components/DrawerView";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import MenuIcon from "@atlaskit/icon/glyph/menu";
import { fade } from "@material-ui/core/styles/colorManipulator";
import InputBase from "@material-ui/core/InputBase";

import SearchIcon from "@material-ui/icons/Search";
import BackIcon from "@material-ui/icons/ArrowBack";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

import { withStyles } from "@material-ui/core/styles";
import { store } from "./redux/store";
import { isMobile } from "react-device-detect";
//import MenuIcon from '@material-ui/icons/Menu';
const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: window.height,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%"
  },
  appBar: {
    position: "fixed",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "fixed"
    }
  },
  content: {
    marginLeft: isMobile ? 0 : drawerWidth,
    flexGrow: 1,
    // backgroundColor: theme.palette.background.default,
    // padding: theme.spacing.unit * 3,
    // overflow: 'scroll',
    // height: window.innerHeight,
    height: "100%"
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  }
});

class App extends Component {
  state = {
    isDrawerOpen: false,
    searchBar: false,
    searchTerm: ""
  };
  static propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };

  closeDrawer = () =>
    this.setState({
      isDrawerOpen: false
    });
  componentDidMount() {}
  handleDrawerToggle = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };
  render() {
    const { classes, theme } = this.props;

    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className={classes.root}>
            <AppBar className={"${classes.appBar} titlebar"}>
              {isMobile ? (
                <div>
                  {this.state.searchBar ? (
                    <Toolbar>
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={() => this.setState({ searchBar: false })}
                        className={classes.navIconHide}
                      >
                        <BackIcon />
                      </IconButton>
                      <div className={classes.search}>
                        <div className={classes.searchIcon}>
                          <SearchIcon />
                        </div>
                        <InputBase
                          id="searchbar"
                          placeholder="Search"
                          onKeyUp={e => {
                            // e.preventDefault();
                            // Number 13 is the "Enter" key on the keyboard

                            if (e.keyCode === 13) {
                              if (
                                this.switch.context.router.history.location.pathname.includes(
                                  "/search"
                                )
                              )
                                this.switch.context.router.history.replace(
                                  "/search/" +
                                    window.document.getElementById("searchbar")
                                      .value
                                );
                              else
                                this.switch.context.router.history.push(
                                  "/search/" +
                                    window.document.getElementById("searchbar")
                                      .value
                                );
                            }
                          }}
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput
                          }}
                        />
                      </div>
                    </Toolbar>
                  ) : (
                    <Toolbar>
                      <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.handleDrawerToggle}
                        className={classes.navIconHide}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Typography variant="h6" color="inherit" noWrap>
                        Resplash
                      </Typography>
                      <div className={classes.grow} />
                      <IconButton
                        color="inherit"
                        onClick={() => this.setState({ searchBar: true })}
                        className={classes.navIconHide}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Toolbar>
                  )}
                </div>
              ) : (
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.handleDrawerToggle}
                    className={classes.navIconHide}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" color="inherit" noWrap>
                    Resplash
                  </Typography>
                  <div className={classes.grow} />
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      id="searchbar"
                      placeholder="Search"
                      onKeyUp={e => {
                        // e.preventDefault();
                        // Number 13 is the "Enter" key on the keyboard

                        if (e.keyCode === 13) {
                          if (
                            this.switch.context.router.history.location.pathname.includes(
                              "/search"
                            )
                          )
                            this.switch.context.router.history.replace(
                              "/search/" +
                                window.document.getElementById("searchbar")
                                  .value
                            );
                          else
                            this.switch.context.router.history.push(
                              "/search/" +
                                window.document.getElementById("searchbar")
                                  .value
                            );
                        }
                      }}
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                      }}
                    />
                  </div>
                </Toolbar>
              )}
            </AppBar>

            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={this.state.isDrawerOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
              >
                <DrawerView closeDrawer={this.handleDrawerToggle} />
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                variant="permanent"
                open
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                <DrawerView closeDrawer={this.handleDrawerToggle} />
              </Drawer>
            </Hidden>

            <div className={classes.content} id="scrollable">
              <div className={classes.toolbar} />
              <Switch ref={link => (this.switch = link)}>
                <Route exact path="/" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route
                  path="/search/:term"
                  render={props => {
                    return (
                      <MyTab
                        history={props.history}
                        type={props.match.params.term}
                        key={props.match.params.term}
                      />
                    );
                  }}
                />
                }
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
