export const ADD_IMAGES = "addIMAGES"
export const CLEAR_IMAGES = "removeIMAGES"

export const addImages = images => {
    return {
        type: ADD_IMAGES,
        images
    }
}

export const clearImages = () => {
    return {
        type: CLEAR_IMAGES,
    }
}