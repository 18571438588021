import React, { Component } from "react";
import "../App.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MyTab from "./Tab";
import { connect } from "react-redux";

class Home extends Component {
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    // console.log(this.props);

    return (
      <div>
        <Tabs
          className="tabbar"
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          // centered
          variant="scrollable"
          style={{
            position: "fixed",
            width: "100%",
            backgroundColor: "#fff",
            zIndex: 10
          }}
        >
          <Tab label="Wallpapers" />
          <Tab label="Landscaps" />
          <Tab label="Nature" />
          <Tab label="Flowers" />
          <Tab label="Earth" />
        </Tabs>
        <div style={{ height: 70 }} />
        {this.state.value === 0 && (
          <MyTab history={this.props.history} type="wallpaper" />
        )}
        {this.state.value === 1 && (
          <MyTab history={this.props.history} type="Landscaps" />
        )}
        {this.state.value === 2 && (
          <MyTab history={this.props.history} type="Nature" />
        )}
        {this.state.value === 3 && (
          <MyTab history={this.props.history} type="flowers" />
        )}
        {this.state.value === 4 && (
          <MyTab history={this.props.history} type="earth" />
        )}
      </div>
    );
  }
}
const mapToProps = state => {
  return {
    store: state
  };
};
export default connect(mapToProps)(Home);
