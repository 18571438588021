import { createStore, compose } from "redux";

import { defaultReducer } from "./reducer";

// export const store = createStore(
//     defaultReducer,
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//     applyMiddleware(logger),
// )
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers();
// applyMiddleware(logger),
// other store enhancers if any
export const store = createStore(defaultReducer, enhancer);
