import React, { Component } from 'react';
import '../App.css';
import { Paper,Button } from '@material-ui/core';

class Contact extends Component {

    render() {
        return (
            <Paper className="page" >
                <div>
                    <h1>Contact us</h1>
                    <p>Developer: Farhan Shaikh </p>
                    <a style={{textDecoration:'none',}} href="http://farhanapps.com">
                    <Button color="primary" variant="contained">
                    website
                    </Button>
                    </a>
                </div>
            </Paper>
        );
    }
}

export default Contact;
