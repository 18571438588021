import React, { Component } from "react";
import "../App.css";
import { Card, CircularProgress, Fab } from "@material-ui/core";

import Chip from "@material-ui/core/Chip";

import { connect } from "react-redux";

import API from "../apis/API";
import { addImages, clearImages } from "../redux/actions";
import StackGrid from "react-stack-grid";
import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import Button from "@atlaskit/button";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { colors } from "@atlaskit/theme";

var page = 1;
var hasMore = true;

let lastScrollY = 0;

class GridItem extends Component {
  state = {
    loader: true
    // image: "https://picsum.photos/150/?random&t=" + new Date().getTime()
  };

  render() {
    return (
      <Card
        className="list-item"
        onClick={() => {
          this.setState({ open: true, item: this.props.index });
          // window.open("/item/" + this.props.index + "/" + encodeURIComponent("https://source.unsplash.com/collection/190727/1600x900"), "_top")
          this.props.viewImage(this.props.item);
        }}
      >
        <div className="thumb-root">
          <img
            alt=""
            onLoad={() => {
              this.setState({ loader: false });
            }}
            src={this.props.item.urls.small}
            className="thumb"
          />
          {this.state.loader && (
            <center>
              <CircularProgress size={50} className="loader" />
            </center>
          )}
          <a
            className="download"
            download={this.props.item.urls.full}
            href={this.props.item.urls.full}
            onClick={e => {
              e.stopPropagation();
            }}
            target="_blank"
            rel="noreferrer"
          >
            <Fab
              size="small"
              color="inherit"
              style={{ backgroundColor: "#ffffff", color: "#000000" }}
              aria-label="Download"
            >
              <i className="material-icons">save_alt</i>
            </Fab>
          </a>
          <p className="description">{this.props.item.description}</p>
        </div>
        <div className="tag-container">
          {this.props.item.tags &&
            this.props.item.tags.slice(0, 5).map((tag, index) => {
              return (
                <Chip
                  color="primary"
                  key={"chip" + index}
                  label={tag.title}
                  className="chip"
                  clickable
                  variant="outlined"
                  onClick={e => {
                    e.stopPropagation();
                    this.props.history.push("/search/" + tag.title);
                  }}
                />
              );
            })}
        </div>
        <div className="row-center">
          <img
            className="logo"
            src={this.props.item.user.profile_image.medium}
            alt={"logo"}
          />
          <div>
            {this.props.item.user.name}
            <br />
            <small style={{ color: "red" }}>❤ {this.props.item.likes}</small>
          </div>
        </div>
      </Card>
    );
  }
}

class MyTab extends Component {
  componentDidMount() {
    page = 1;
    this.props.dispatch(clearImages());
    this.loadImages(page);
    this.titlebar = window.document.getElementsByClassName("titlebar")[0];
    this.titlebarclass = window.document.getElementsByClassName(
      "titlebar"
    )[0].className;
    try {
      this.tabbar = window.document.getElementsByClassName("tabbar")[0];
      this.tabbarclass = window.document.getElementsByClassName(
        "tabbar"
      )[0].className;
    } catch {}

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.titlebar.className = this.titlebarclass;
  }
  titlebarclass = "";
  tabbarclass = "";

  handleScroll = () => {
    if (lastScrollY < window.scrollY) {
      //scrolling to bottom
      this.titlebar.className = this.titlebarclass + " hidden";
      try {
        this.tabbar.className = this.tabbarclass + " hidden";
      } catch {}
    } else {
      this.titlebar.className = this.titlebarclass;
      try {
        this.tabbar.className = this.tabbarclass;
      } catch {}
      // window.document.getElementById("tabbar").style.display = 'block';
    }
    // if (isMobile) {
    // }
    lastScrollY = window.scrollY;
    if (this.state.loader) return;
    // console.log(
    //   window.document.body.scrollHeight -
    //     window.document.documentElement.clientHeight +
    //     " | " +
    //     window.document.documentElement.offsetHeight
    // );
    // console.log(window.scrollY);
    var mHeight =
      window.document.body.scrollHeight -
      window.document.documentElement.clientHeight;
    var diff = mHeight - window.scrollY;
    if (diff < 100 && hasMore) {
      // return;
      this.setState({ loader: true });
      // this.grid.updateLayout();
      setTimeout(() => {
        this.loadImages(++page);
      }, 1);
    }
  };
  loadImages = page => {
    API.getImages(this.props.type, page).then(response => {
      // console.log(response);
      this.setState({ loader: false });
      if (response.results.length === 0) {
        hasMore = false;
        return;
      }
      this.props.dispatch(addImages(response));
      this.setState({
        showingResults:
          "Showing " +
          this.props.store.images.length +
          " out of " +
          response.total +
          " images"
      });
      // this.grid.updateLayout();
      // setTimeout(() => {
      //     this.grid.updateLayout();
      // }, 200);
    });
  };
  state = {
    open: false,
    loader: true
  };
  close = () => this.setState({ isShowModel: false });
  render() {
    return (
      <div>
        <ModalTransition>
          {this.state.isShowModel && (
            <Modal
              onClose={this.close}
              shouldCloseOnEscapePress={true}
              shouldCloseOnOverlayClick={true}
              width={window.innerWidth <= 768 ? "100%" : 500}
              autoFocus={false}
              scrollBehavior="outside"
            >
              <div className="modelbox">
                <div className="row-center">
                  <img
                    className="logo"
                    src={this.state.visibleItem.user.profile_image.medium}
                    alt={"logo"}
                  />
                  <div>
                    {this.state.visibleItem.user.name}
                    <br />
                    <small style={{ color: "red" }}>
                      ❤ {this.state.visibleItem.likes}
                    </small>
                  </div>
                  <Button
                    onClick={this.close}
                    className="close"
                    appearance="link"
                    spacing="none"
                  >
                    <CrossIcon
                      label="Close Modal"
                      primaryColor={colors.R400}
                      size="large"
                    />
                  </Button>
                </div>
                <div className="thumb-root">
                  <img
                    alt=""
                    onLoad={() => this.setState({ itemloader: false })}
                    src={this.state.visibleItem.urls.regular}
                    className="model-image"
                  />
                  {this.state.itemloader && (
                    <center>
                      <CircularProgress
                        style={{ marginBottom: 100, marginTop: 50 }}
                        size={50}
                        className="loader"
                      />
                    </center>
                  )}
                  <a
                    className="download"
                    download={this.state.visibleItem.urls.full}
                    href={this.state.visibleItem.urls.full}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Fab
                      size="medium"
                      color="inherit"
                      style={{ backgroundColor: "#ffffff", color: "#000000" }}
                      aria-label="Download"
                    >
                      <i className="material-icons">save_alt</i>
                    </Fab>
                  </a>
                  <p className="description">
                    {this.state.visibleItem.description}
                  </p>
                </div>
                <div className="tag-container">
                  {this.state.visibleItem.tags &&
                    this.state.visibleItem.tags.map((tag, index) => {
                      return (
                        <Chip
                          color="primary"
                          key={"chip" + index}
                          label={tag.title}
                          className="chip"
                          clickable
                          variant="outlined"
                          onClick={e => {
                            e.stopPropagation();
                            this.props.history.push("/search/" + tag.title);
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            </Modal>
          )}
        </ModalTransition>

        <div className="status-bar">
          <p>{this.state.showingResults}</p>
        </div>
        <StackGrid
          monitorImagesLoaded={true}
          gridRef={grid => (this.grid = grid)}
          columnWidth={window.innerWidth <= 768 ? "100%" : "30%"}
          duration={0}
        >
          {this.props.store.images.map((item, i) => (
            <GridItem
              {...this.props}
              viewImage={this.showImage}
              index={i}
              item={item}
              key={"item-" + i}
            />
          ))}
        </StackGrid>
        {this.state.loader && (
          <center>
            <CircularProgress
              style={{ marginBottom: 100, marginTop: 50 }}
              size={50}
              className="loader"
            />
          </center>
        )}
      </div>
    );
  }

  showImage = item => {
    this.setState({ visibleItem: item, isShowModel: true, itemloader: true });
  };
}
const mapToProps = state => {
  return {
    store: state
  };
};
export default connect(mapToProps)(MyTab);
