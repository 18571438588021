
export default class API {
    static getImages = (type,page) => {
        return fetch(
            "https://api.unsplash.com/search/photos/?client_id=f9bc0e20f7e52a6fc648546c6816cafbb28d302431e019fcab64193de4fc02ab&page="+page+"&query="+type+"&order_by=latest",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            }
        )
            .then(response => response.json());
    }
}